import React, { useEffect, useContext, useState, useRef } from 'react';
import {Link} from 'gatsby'
import {UnmountClosed} from 'react-collapse';
import Captions from './Captions';
import playButton from '../images/play_button.png'
import pauseButton from '../images/pause_button.png'
import chevronDown from '../images/chevron-down.png'
import AnimateHeight from 'react-animate-height';
import Fade from './Fade';

const Transcript = (props) => {

	let {chapters, topic, activeChapter, seekVideo, pauseVideo, resumeVideo, showPrompt, setShowPrompt, isPlaying, scrollToChapter } = props;

	const enableTouch = useRef(false);
	
	const [showBackButton, setShowBackButton] = useState(true);

	const goToChapter = (index) => {
		if(index === activeChapter || !chapters[index].words){
			return;
		}

		console.log(`going to chapter ${index}`)

		let start = chapters[index].words[0].start;

		if(start){
			seekVideo(start);
		}
	}

	const toggleVideo = (isActive, isPlaying , index) => {
		console.log(`toggle`);
		if(isActive && isPlaying){
			console.log(`pause`)
			pauseVideo();
		} else if(isActive && !isPlaying){
			resumeVideo();
		} else {
			goToChapter(index);
		}
	}

	let chapterRefs = {};

	chapters.forEach((chapter, index) => {
		chapterRefs[index] = useRef();
	});

	const parseWords = (transcript, words) => {
		let parsedWords = [];
		let transcriptOffset = 0;
		let currentOffset = 0;

		if(transcript[0].transcriptOffset !== 0){
			transcriptOffset = words[0].startOffset;
		}
		words.forEach(word => {

			// Non linked text (punctuation)
			if(word.startOffset > currentOffset){
				let text = transcript.slice(currentOffset, word.startOffset - transcriptOffset);
				parsedWords.push({
					type: word.length > 1 ? `linked`: `not-linked`,
					text: text,
					before: null,
					start: word.start,
					end: word.end
				});
				currentOffset = word.startOffset - transcriptOffset;
			}

			parsedWords.push({
				type: `linked`,
				text: transcript.slice(word.startOffset - transcriptOffset, word.endOffset - transcriptOffset),
				start: word.start,
				end: word.end
			});
			currentOffset = word.endOffset - transcriptOffset;
		});

		return parsedWords;
	}

	useEffect(() => {
		setTimeout(() => setShowPrompt(true), 1000);
	}, []);

	useEffect(() => {

		if(!window.history.length){
			setShowBackButton(true)
		}


		chapters.forEach((chapter, index) => {
			if(!chapter.words) return;
			console.time(`parse`)
			chapter.parsedWords = parseWords(chapter.transcript, chapter.words);
			console.timeEnd(`parse`)
		})

		chapters.forEach((chapter, index) => {
			let string = ``;
			if(!chapter.words) {
				string = `<p>${chapter.transcript}</p>`;
			} else {
				if(index === activeChapter){
					chapter.parsedWords.forEach(word => {
						string = `${string}<span data-start="${word.start}">${word.text}</span>`
					});
	
					chapterRefs[index].current.addEventListener(`click`, function(e){
						let clickedWord = e.target;
						let startTime = clickedWord.dataset.start;
						if(startTime){
							seekVideo(startTime)
						}
					})
				} else {
					string = `<p>`
					chapter.parsedWords.forEach(word => {
						string = `${string}${word.text}`
					})
					string = `${string}</p>`;
				};
			}
			
			chapterRefs[index].current.innerHTML = string;
		})
		enableTouch.current = true;
	}, [activeChapter]);

	const buttonText = (isActive, isPlaying) => {
		if(isActive){
			if(isPlaying){
				return `Pause`;
			} else {
				return `Resume`
			}
		} else {
			return `Play`
		}
	}

	const buttonIcon = (isActive, isPlaying) => {
		let img;
		let classes;
		if(isActive && isPlaying){
			img = pauseButton;
			classes = `pause-button`;
		} else {
			img = playButton;
			classes = `play-button`

			if(!isPlaying && isActive){
				classes = `${classes} yellow`
			}
		}

		return <img className={classes} src={img} />
	}

	return (	
		<div className="captions" id="captions-parent">
			<div className="captions-inner">
				<h1 className="text-body title">{topic.title}</h1>

				<p className="topic-intro">
					{topic.intro}
				</p>

				<Fade show={showPrompt}>
					<span className="captions-nav-prompt">Click on any word to navigate the video</span>
				</Fade>

				{chapters && chapters.map((chapter, index) => {
					let isActive = index === activeChapter;
					return (
						<div key={`chapter-${index}`}>
							<div className="chapter" onClick={() => toggleVideo(isActive, isPlaying, index)}>
								{chapter.title && <h2 className="chapter-title">{chapter.title}</h2>}
								<div className="play-wrapper">
									<h3 className="chapter-speaker">{chapter.speaker}</h3>
									<div className="play-button">
										<span className={isActive ? `active` : ``}>{buttonText(isActive, isPlaying)}</span>
										{buttonIcon(isActive, isPlaying)}
									</div>
								</div>
							</div>
							<AnimateHeight duration={ 300 } height={ isActive ? `auto` : 100 } onAnimationEnd={() => scrollToChapter()}>
								<div className="transcript text-body" ref={chapterRefs[index]} id={`captions-${index}`} />
							</AnimateHeight>
							{!isActive && <div className="chapter-preview-cutter"/>}
							{/* {!isActive && <span onClick={() => goToChapter(index)} className="more-button"><img src={chevronDown} />More of this answer</span>} */}
						</div>
					);
				})}
			</div>
		</div>
	);
};
export default Transcript;