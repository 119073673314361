import React, { useEffect, useRef, useState, useContext } from 'react';
import Word from './Word';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import {VideoStatusContext} from './VideoStatusContext';

const Captions =  React.memo(({isActive, index, words}) => {

	return (
		
		<div className="transcript text-body" id={`captions-${index}`}>

			{words.map((item, i) => {
				return isActive ? <Word 
					key={`${item.text}-${i}`} 
					word={item.text}
					active={item.active}
					start={item.start}
				/> : ``;
			})}
		</div>
	);
});


const InactiveCaptions = ({words}) => {
	let stringArray = words.split(` `);
	let excerptLength = 30;
	if(words.length){
		return (
			<span>
				{stringArray.map((word, i) => i < excerptLength ? `${word} ` : null)}
				...
			</span>
		);
	} else {
		return null;
	}
};

Captions.displayName = `Captions`;

export default Captions;




