import React, { useState } from 'react';

const VideoStatusContext = React.createContext([{test: true}, () => {}]);

const VideoStatusProvider = (props) => {
	const [state, setState] = useState({});
	return (
		<VideoStatusContext.Provider value={[state, setState]}>
			{props.children}
		</VideoStatusContext.Provider>
	);
};

export { VideoStatusContext, VideoStatusProvider };
