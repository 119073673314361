import React from 'react';

const GyroPermission = ({initGyro}) => {
	return (
		<div className="gyro-permission">
			<p>Please allow us to use the gyroscope.</p>
			<button onClick={() => initGyro()}>Continue</button>
		</div>
	)
}

export default GyroPermission;