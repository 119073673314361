import React, {useState, useEffect, useRef, useCallback} from 'react';
import Player from '@vimeo/player';
import {Link} from 'gatsby';
import Img from 'gatsby-image';
import Controls from './Controls';
import Transcript from './Transcript';
import playButton from '../images/play_button.png';
// import pauseButton from '../images/pause_button.png';
import loaderIcon from '../images/loader.svg';
import explainerImg from '../images/nav-explainer.gif';
import gsap from 'gsap';
import InApp from 'detect-inapp';
import {getUA} from 'react-device-detect';	
import GyroPermission from './GyroPermission';
import { useQueryParam, NumberParam } from "use-query-params";

const VIDEO_ZOOM = 1.2;

const Video = (props) => {
	const {
		videoId,
		background,
		topic
	} = props;

	let 
		dimensions,
		out,
		slope
	;

	const activeTopic = require(`../data/${topic.slug}-parsed.json`);


	if(typeof window !== `undefined`) {

		dimensions = {
			w: window.innerWidth * VIDEO_ZOOM,
			h: window.innerWidth * VIDEO_ZOOM * 1.77777733333
		};

		let containerDimensions = {
			w: dimensions.w,
			h: dimensions.h
		};

		let speakerNameBuffer = window.innerHeight / 10 + 60;

		out = {
			start: ((containerDimensions.h - window.innerHeight) / 2 + speakerNameBuffer) * -1,
			end: (containerDimensions.h - window.innerHeight) / 2 + speakerNameBuffer, // Add some buffer space for speaker name
		};


		slope = (out.end - out.start) / 50;
	}

	const [isPlaying, setIsPlaying] = useState(false);
	const videoObj = useRef(null);
	const [initPlay, setInitPlay] = useState(false);
	
	const captionsObject = useRef(null);
	const initBeta = useRef(null);
	const [loading, setLoading] = useState(true);
	const [urlTime, setUrlTime] = useQueryParam(`t`, NumberParam);

	const [showPrompt, setShowPrompt] = useState(true);


	const [askForGyro, setAskForGyro] = useState(false);
	const [isInApp, setIsInApp] = useState(false);

	const videoRef = useRef(null);
	const transcriptWrapperRef = useRef(null);
	const captionsParent = useRef(null);

	const [showEndScreen, setShowEndScreen] = useState(false);

	const [globalTime, setGlobalTime] = useState(0);

	const playButtonRef = useRef(null);

	const toggleFullScreen = useCallback(() => {
		if (!document.fullscreenElement) {
			try {
				document.documentElement.requestFullscreen();
			} catch(e){
				console.log(`full screen not supported: ${e}`)
			}
		} else {
			if (document.exitFullscreen) {
				document.exitFullscreen(); 
			}
		}

		playButtonRef.current.removeEventListener(`click`, toggleFullScreen, false);
	});

	useEffect(() => {

		if(initPlay){
			return;
		}

		if(playButtonRef.current){
			playButtonRef.current.addEventListener(`click`, toggleFullScreen, false);
		}

	}, [loading]);

	const chapters = activeTopic.chapters.map(chapter => {
		if(!chapter.words){
			return {
				...chapter
			};
		}
		let wordsLength = chapter.words.length;
		return {
			...chapter,
			start: chapter.words[0].start - 9,
			end: chapter.words[wordsLength - 1].start + .5
		};

	});

	let stopUpdate = useRef(false);

	const [activeChapter, setActiveChapter] = useState(0);

	const chapterStateRef = useRef(activeChapter);

	const updateChapter = data => {
		chapterStateRef.current = data;
		setActiveChapter(data);
	};

	const scrollToChapter = () => {
		console.log(`scrolling to ${chapterStateRef.current}`);
		let toScroll = captionsObject.current[chapterStateRef.current].offsetTop - 150;
		if(captionsParent.current){
			captionsParent.current.scrollTo({left: 0, top: toScroll, behavior: `smooth`});
		}
	};

	const orientation = useRef();
	
	const initGyro = () => {

		setAskForGyro(false);

		if ( window.DeviceOrientationEvent !== undefined && typeof window.DeviceOrientationEvent.requestPermission === `function` ) {

			window.DeviceOrientationEvent.requestPermission().then( function ( response ) {

				if ( response == `granted` ) {

					window.addEventListener( `deviceorientation`, onDeviceOrientationChangeEvent, false );

				}

			} ).catch( function ( error ) {

				console.error( `DeviceOrientationControls: Unable to use DeviceOrientation API:`, error );

			} );

		} else {

			window.addEventListener( `deviceorientation`, onDeviceOrientationChangeEvent, false );

		}

	};

	const onDeviceOrientationChangeEvent = (ev) => {

		if(!initBeta.current) {
			initBeta.current = ev.beta;
		}

		let calcBeta = ev.beta - initBeta.current;
		let newBeta = gsap.utils.clamp(-10, 25, calcBeta);
		let clampedBeta = gsap.utils.clamp(-10, -3, calcBeta);
		let newOpacity = gsap.utils.mapRange(-10, -3, 1, -1, clampedBeta);
		let yPos = (out.start + slope * (newBeta + 25));

		if(videoRef.current){
			gsap.to(videoRef.current, {
				y: yPos,
				ease: `sine.inOut`,
				duration: .05
			});
		}

		if(transcriptWrapperRef.current){
			gsap.to(transcriptWrapperRef.current, {
				opacity: newOpacity,
			});

			let newZIndex = newOpacity > 0.05 ? 9 : -1;

			transcriptWrapperRef.current.style.zIndex = newZIndex;
		}

	};

	const recalibrate = () => {
		console.log(`recalib`);
		initBeta.current = 0;
		window.removeEventListener( `deviceorientation`, onDeviceOrientationChangeEvent, false );
		let fakeEv = {beta: 0};
		onDeviceOrientationChangeEvent(fakeEv);
		window.addEventListener( `deviceorientation`, onDeviceOrientationChangeEvent, false );
	};

	const seekVideo = async (time) => {

		if(isNaN(time)){
			time = 0;
		}
		console.log(`going to ${time}`);
		setLoading(true);
		if(videoObj.current){
			console.log(`pausing video`);
			try {
				await videoObj.current.pause();
			} catch(e){
				alert(e);
			}
			try {
				await videoObj.current.setCurrentTime(time);
			} catch (e) {
				alert(e);
			}

			let paused;
			
			try {
				paused = videoObj.current.getPaused();
			} catch(e) {
				alert(e);
			}
			
			if(paused){
				try {
					await videoObj.current.play();
				} catch(e){
					alert(e);
				}
				setIsPlaying(true);
			}
			
			setLoading(false);
			setShowPrompt(false);
		}
	};

	useEffect(() => {

		let dimensions = {
			w: window.innerWidth * VIDEO_ZOOM,
			h: window.innerWidth * VIDEO_ZOOM * 1.77777733333
		};

		const inapp = new InApp(navigator.userAgent || navigator.vendor || window.opera);

		let inApp = inapp.isInApp;


		// DuckDuckGo browser behaves like an inAppBrowser
		if(
			getUA.toLowerCase().includes(`mobile`)
			&& getUA.toLowerCase().includes(`duckduckgo`)
		){
			inApp = true;
		}


		setIsInApp(inApp);
		if(inApp){
			setAskForGyro(true);
		}
		
		const playerObj = new Player(`video-${videoId}`, {
			id: videoId,
			autoplay: false,
			autopause: false,
			controls: inApp,
			muted: false,
			volume: 1,
			width: dimensions.w,
			height: dimensions.h
		});


		videoObj.current = playerObj;

		videoObj.current.on(`timeupdate`, function(data){

			// Save current time to local storage so that we can resume on future sessions
			localStorage.setItem(videoId, data.seconds);
			if(stopUpdate.current){
				return;
			}
			setGlobalTime(data.seconds);
			progressTranscript(data.seconds);
			
		});
		videoObj.current.on(`bufferstart`, function(){
			setLoading(true);
		});

		videoObj.current.on(`bufferend`, function(){
			setLoading(false);
			
		});

		videoObj.current.on(`loaded`, function(){
			setLoading(false);
		});

		videoObj.current.on(`play`, function(){
			if(!initPlay){
				initStuff();
			}
		});

		videoObj.current.on(`ended`, function(){
			setShowEndScreen(true);
		});


	}, [videoId]);

	const play = async (savedTime) => {
		if(videoObj.current){
			videoObj.current.play().then(result => {
				if(savedTime){
					console.log(`Attempting to start from ${savedTime}`);
					videoObj.current.setCurrentTime(savedTime).then(result => {
						console.log(`starting from ${savedTime}`);
					}).catch(e => console.error(`Something went wrong starting from specific time: ${e}`));
				} else {
					console.log(`No time`);
				}
			});
			setIsPlaying(true);
			setLoading(false);
		}
	};

	const initStuff = useCallback(() => {
		captionsParent.current = document.getElementById(`captions-parent`);

		let captionsRefList = [];
		chapters.forEach((chapter, index) => {
			captionsRefList.push(document.getElementById(`captions-${index}`));
		});
		captionsObject.current = [...captionsRefList];

		setInitPlay(true);
		setIsPlaying(true);
		videoObj.current.element.setAttribute(`data-hj-allow-iframe`, `true`)
	}, [chapters]);


	const initVideoPlay = async () => {	
		setLoading(true);
		if(initPlay){
			play();
			return;
		}

		initStuff();
		initGyro();
		// Getting saved time for current video from last session
		let duration = await videoObj.current.getDuration();
		let savedTime = 35;

		if(urlTime){
			if(urlTime < duration){
				savedTime = urlTime;
				clearUrlTime();
			}
		} else {
			let cachedTime = localStorage.getItem(videoId);
			if(cachedTime < duration - 10){
				savedTime = cachedTime;
			}
		}

		play(savedTime);

		console.log(`duration: ${duration}`);
		
		
	};

	const clearUrlTime = () => {
		setUrlTime(undefined);
	};


	const progressTranscript = (time) => {
		updateChapters(time);

		let index = chapterStateRef.current;
		let words = captionsObject.current[index].children;

		if(time < words[0].start){
			return;
		}
		for(let i = 0; i< words.length; i++){
			if (time > words[i].dataset.start - 0.22) {
				words[i].classList.add(`active`);
				if(time < words[i+1].start){	
					break;
				}
			} else {
				words[i].classList.remove(`active`);
			}
		}
		
	};

	const refreshTranscript = (time) => {
		let words = captionsObject.current.children;

		for(let i = 0; i< words.length; i++){
			if(i === words.length - 1){
				if(time > words[i].dataset.start - 0.02){
					updateWord(words[i]);
				}
			}
			if(time > words[i].dataset.start - 0.02){  
				if(i > 0 && !words[i-1].dataset.start){
					words[i-1].dataset.start;
					updateWord(words[i-1]);
				}
				updateWord(words[i]);
			} else {
				words[i].classList.remove(`active`);
			}
		}
	};
	const updateWord = (word) => {
		word.classList.add(`active`);
	};


	const updateChapters = time => {
		chapters.forEach((chapter, index) => {
			if(time && (time < chapter.end && time > chapter.start)){
				if(chapterStateRef.current !== index){
					console.log(`setting chapter to ${index}`);
					updateChapter(index);
					refreshTranscript(time);
				}
			}
		});
	};

	const changeChapter = index => {
		stopUpdate.current = true;
		setTimeout(() => stopUpdate.current = false, 200);
		updateChapter(index);
		setShowPrompt(false);
	};

	const toggleVideo = () => {
		if(videoObj.current){
			if(!initPlay){
				initVideoPlay();
			}
			if(isPlaying){
				pauseVideo();
			} else {
				playVideo();
			}
		}
	};

	const pauseVideo = () => {
		if(videoObj.current){
			videoObj.current.pause().then(function(){
				console.log(`paused success`);
				setIsPlaying(false);
			}).catch(function(e){
				console.log(e);
			});
		} else {
			console.error(`video not created yet`);
		}
	};

	const playVideo = () => {
		if(videoObj.current){
			videoObj.current.play().then(function(){
				console.log(`play success`);
				setIsPlaying(true);
				if(!initPlay){
					setInitPlay(true);
				}
			}).catch(function(e){
				console.log(e);
			});
		} else {
			console.error(`video not created yet`);
		}
	};

	const activeStyleLoader = {
		opacity: 1,
		zIndex: 99,
		background: `rgba(0,0,0,.5)`
	};

	const inactiveStyleLoader = {
		opacity: 0,
		zIndex: -1,
		background: `transparent`
	};

	// const showPlay = () => {
	// 	return !isPlaying && !loading;
	// };

	const updateUrl = async () => {
		let currentTime = activeChapter === 0 ? 0 : chapters[activeChapter].start;
		if(currentTime){
			setUrlTime(Math.floor(currentTime));
		}

		return true;
	}

	const getGyroTitle = (activeChapter) => {
		if(typeof activeChapter === `undefined`) {
			return;
		}

		let current = chapters[activeChapter];
		if(
			current.title 
			&& globalTime > current.start
			&& globalTime < current.words[0].start
		) {
			return <span className="gyro-title"><strong>{current.title}</strong></span>
		} else {
			return (
				<span className="gyro-title">
					<strong>
						{current.speaker}
					</strong>
					<br/>
					{ current.speaker_position 
						? current.speaker_position 
						: current.title
					}
				</span>
			)
		}
	}


	return (	
		<div className={`main-wrapper ${isPlaying ? `playing` : `stopped`}`}>	

			<div className="orientation-prompt">
				<p>Please keep your phone in portrait mode for the best experience.</p>
			</div>

			{askForGyro ? <GyroPermission initGyro={() => initGyro()} /> : null }
			
			<Controls 
				clearUrlTime={() => clearUrlTime()} 
				updateUrl={() => updateUrl()} 
				recalibrate={() => recalibrate()}
				playVideo={() => play()}
				pauseVideo={() => pauseVideo()}
			/>
			
			
			{ isPlaying ? <div className="controller" onClick={() => toggleVideo()} /> : null }
			
			<div className={`transcript-wrapper bg-pearl`} ref={transcriptWrapperRef} >
				<Transcript 
					isPlaying={isPlaying} 
					dark={topic.slug === `blockchain`} 
					showPrompt={showPrompt}
					setShowPrompt={(show) => setShowPrompt(show)} 
					chapters={chapters} 
					activeChapter={activeChapter} 
					seekVideo={(time) => seekVideo(time)} 
					pauseVideo={() => pauseVideo()}
					resumeVideo={() => playVideo()}
					changeChapter={(index) => changeChapter(index)} 
					scrollToChapter={() => scrollToChapter()}
					topic={activeTopic}
				/> 
			</div>
			
			<div className="video-wrapper">

				<div className="container">

					{ !loading ? getGyroTitle(activeChapter) : null }

					{showEndScreen ?
						<Link to={topic.end_link} title="Click here to continue" className="endscreen">
							<Img className="endscreen-image" fluid={topic.end_image.childImageSharp.fluid} />
						</Link>
						:null
					}

					{/* <VideoPlayer videoId={videoId} toPlay={isPlaying} setIsPlaying={(status) => setIsPlaying(status)} position={videoPosition} initGyro={initGyro} updatedTime={updatedTime} /> */}
					{!isPlaying && !loading ?
						<div className="play-pause" ref={playButtonRef} style={{pointerEvents: isInApp ? `none` : `auto`}} onClick={() => initVideoPlay()}>
							{!initPlay && <div className="explainer">
								<img src={explainerImg} alt="Tilt your device to navigate" />
							</div>}

							<button>
								<img alt="playIcon" src={playButton} width="100" height="100" />
								PLAY
							</button>
						</div>
						: null
					}

					{loading ?
						<div className="loader">
							<img alt="playIcon" src={loaderIcon} />
						</div>
						: null
					}
					
					<div 
						className="video-item" 
						id={`video-${videoId}`} 
						key={videoId} 
						ref={videoRef}
					/>
					
				</div>

			</div>
		</div>
	);
};

export default Video;
