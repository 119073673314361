import React, { useEffect, useRef, useState } from 'react';
import {Link} from 'gatsby';
import { Facebook, Twitter, Whatsapp, Telegram, Mail } from 'react-social-sharing'
import linkIcon from '../images/link-icon.svg';


const Controls = ({ clearUrlTime, updateUrl, time, recalibrate, pauseVideo, playVideo }) => {
	const [showShare, setShowShare] = useState(false);
	const [showCopyText, setShowCopyText] = useState(false);
	const [currentHref, setCurrentHref] = useState(``);
	const [showBackButton, setShowBackButton] = useState(true);

	const inputRef = useRef(null);

	useEffect(() => {
		if(!window.history.length){
			setShowBackButton(false);
		}
	}, [])

	const share = async () => {
		await updateUrl();
		setCurrentHref(document.location.href);
		setShowShare(true);
		pauseVideo();
	};

	const copy = () => {
		if(showCopyText){
			return;
		}
		inputRef.current.select();
		document.execCommand(`copy`);
		setShowCopyText(true);
	};

	const close = () => {
		clearUrlTime();
		setShowShare(false);
		setShowCopyText(false);
		playVideo();
	}
	
	return (
		<div className="controls">
		
			{showShare && 
				<>
					<div className="sharing-overlay" onClick={() => close()} />
					<div className="sharing-container">
						<span className="close" onClick={() => close()}>×</span>
						<span className="sharing-title">Sharing from current chapter</span>
						
						<input className="urlBox" onClick={() => inputRef.current.select()} readOnly ref={inputRef} value={currentHref} size={currentHref.length} />
						<div className="link-wrapper">
							<div className="link-button" onClick={() => copy()}>
								<div className={`copy-link-button ${showCopyText ? `hidden` : `show`}`}>
									<span className={`copy-text ${showCopyText ? `hidden` : `show`}`}>Copy Link</span>
								</div>
								
								<span className={`copy-info ${showCopyText ? `show` : `hidden`}`}>Link copied!</span>
							</div>
							
						</div>
						<div className="icons">
							<Facebook link={currentHref} />
							<Twitter link={currentHref} />
							<Whatsapp link={currentHref} />
							<Telegram link={currentHref} />
							<Mail link={currentHref} />
						</div>
					</div>
				</>
			}

			{showBackButton && <Link className="back-button" to="/">Home</Link>}

			<button className="share-button" onClick={()=> share()}>
				Share
			</button>

			{/* <div className="autoplay-overlay center-camera" onClick={() => recalibrate()}>
				Center camera
			</div> */}

		</div>
	);
};

export default Controls;